import {Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {SlidePanelService} from '../../../slide-panel/slide-panel.service';
import {FilterService} from '../../../../@core/filter.service';

@Component({
    selector: 'app-list-preview',
    template: `
        <ng-container *ngIf="data?.length">
            <ng-content *ngTemplateOutlet="tableRef; context: {hasLimit: hasLimit, detailView: detailView}"></ng-content>
        </ng-container>
        <ng-template #tableRef let-hasLimit="hasLimit" let-detailView="detailView !== false">
            <div [class.table-responsive]="hasScrollContainer" *ngIf="isTable; else innerTemplate">
                <table class="table {{tableClasses}}" [class.table-sm]="smallTable" [class.small]="smallTable">
                    <ng-container *ngIf="tableStartTemplate">
                        <ng-container *ngTemplateOutlet="tableStartTemplate"></ng-container>
                    </ng-container>
                    <thead *ngTemplateOutlet="tableHeadTemplate; context: {detailView: detailView}"></thead>
                    <tbody>
                        <ng-container *ngTemplateOutlet="innerTemplate"></ng-container>
                    </tbody>
                    <ng-container *ngIf="tableEndTemplate">
                        <ng-container *ngTemplateOutlet="tableEndTemplate"></ng-container>
                    </ng-container>
                </table>
            </div>
            <ng-template #innerTemplate>
                <ng-container *ngFor="let row of (hasLimit && limit ? getCurrentPageData().slice(0, limit) : getCurrentPageData()); let i = index; let l = last">
                    <ng-content *ngTemplateOutlet="tableRowTemplate; context: {row: row, rows: data, index: i, last: l, detailView: detailView}"></ng-content>
                </ng-container>
            </ng-template>
            <ng-container *ngIf="!detailView && shouldShowMoreButton">
                <a class="btn btn-light btn-block btn-sm mt-3"
                   (click)="slidePanelService.add(tableRef, {title: title})"
                   *ngIf="hasLimit && limit && data.length > limit; else limitNotReachedTmp" tabindex="-1">+{{data.length - limit}} more</a>
                <ng-template #limitNotReachedTmp>
                    <a class="btn btn-light btn-block btn-sm mt-3"
                       (click)="slidePanelService.add(tableRef, {title: title})"
                       *ngIf="enforceMoreButton" tabindex="-1">More</a>
                </ng-template>
            </ng-container>
            <div class="list-preview-pagination mt-4 d-print-none" *ngIf="shouldShowPagination()">
                <ngb-pagination
                    (pageChange)="onPageChange($event)"
                    [page]="page"
                    [pageSize]="getPageSize()"
                    [collectionSize]="data?.length"
                    [maxSize]="maxSize"
                    [boundaryLinks]="true">
                </ngb-pagination>
                <div>
                    <select [ngModel]="getPageSize()" (ngModelChange)="onPageSizeChange($event)" class="body-select">
                        <option *ngFor="let option of getPageSizeOptions()" [ngValue]="option.value">{{option.name}}</option>
                    </select>
                </div>
            </div>
        </ng-template>
        <div class="text-muted" *ngIf="data && !data.length">{{emptyText}}</div>
    `,
    styleUrls: ['./list-preview.component.scss'],
})
export class ListPreviewComponent {
    @Input() data: any[];
    @Input() limit = 5;
    @Input() tableHeadTemplate?: any;
    @Input() tableRowTemplate?: any; // TODO: rename this to rowTemplate
    @Input() tableStartTemplate?: TemplateRef<any>;
    @Input() tableEndTemplate?: TemplateRef<any>;
    @Input() tableClasses = '';
    @Input() smallTable = true;
    @Input() title?: string;
    @Input() emptyText = 'No data to show.';
    @Input() isTable = true;
    @Input() hasLimit = true;
    @Input() shouldShowMoreButton = true;
    @Input() enforceMoreButton = false;
    @Input() shouldShowPaginationComponents = true;
    @Input() detailView = false;
    @Input() hasScrollContainer = true;

    // Pagination
    @Input() maxSize = 7;

    page = 1;

    @ViewChild('tableRef', {static: true}) tableTemplate: TemplateRef<ElementRef>;
    @Output() paginationChange = new EventEmitter<any>();

    constructor(public slidePanelService: SlidePanelService,
                private filterService: FilterService) {
    }

    shouldShowPagination() {
        return this.shouldShowPaginationComponents && this.data.length > this.getPageSize() && (!this.limit || this.limit > this.getPageSize());
    }

    getPageSize() {
        return this.filterService.detailViewPageSize.value;
    }

    getPageSizeOptions() {
        return this.filterService.detailViewPageSizeOptions;
    }

    getCurrentPageData() {
        if (!this.shouldShowPagination()) return this.data;

        const startIndex = (this.page - 1) * this.getPageSize();
        return this.data.slice(startIndex, startIndex + this.getPageSize());
    }

    onPageSizeChange(value) {
        this.page = 1;
        this.filterService.detailViewPageSize = this.filterService.detailViewPageSizeOptions.find(option => option.value === value);
        this.paginationChange.emit();
    }

    onPageChange(page) {
        this.page = page;
        this.paginationChange.emit();
    }
}
